import React, { useState, useEffect } from "react";
import "./main.css";



//---- Initialization

export default function Main() {

  return (
    <div className="mainPage">
      {/* <SummaryInfo /> */}
      <div className="mainSummary">
        <div className="mainSummaryContainer">
          <div className="mainSummaryTitle">City of Surrey - Summary</div>
          <div className="container">

          </div>
        </div>
      </div>
    </div>
  );
}
