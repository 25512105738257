import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Home } from '@material-ui/icons';

import './sidebar.css';

export default function Sidebar() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className={`sidebarTitle" ${selectedItem === 'main' ? 'active' : ''
            }`}
            onClick={() => handleItemClick('main')}>
            <Link className="sidebarLink" to="/">
              Overview
            </Link>
          </h3>

          <h3 className={`sidebarTitle" ${selectedItem === 'newwesthome' ? 'active' : ''
            }`}
            onClick={() => handleItemClick('newwesthome')}
            style={{ marginTop: '15px' }}
          >

            <Link className="sidebarLink" to="/newwest/home">
              New Westminster
            </Link>
          </h3>
          <ul className="sidebarList">
            <li
              className={`sidebarListItem ${selectedItem === 'newwestgoals' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('newwestgoals')}
            >
              <Link className="sidebarLink" to="/newwest/goals">
                Emission Goals and Status
              </Link>
            </li>
            <li
              className={`sidebarListItem ${selectedItem === 'newwestadd' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('newwestadd')}
            >
              <Link className="sidebarLink" to="/newwest/add">
                Add Emission Data
              </Link>
            </li>
            <li
              className={`sidebarListItem ${selectedItem === 'newwestlive' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('newwestlive')}
            >
              <Link className="sidebarLink" to="/newwest/live">
                Live Emission Data
              </Link>
            </li>
            <li
              className={`sidebarListItem ${selectedItem === 'newwestpast' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('newwestpast')}
            >
              <Link className="sidebarLink" to="/newwest/past">
                Historical Emission Data
              </Link>
            </li>
          </ul>

          <h3 className={`sidebarTitle" ${selectedItem === 'surreymain' ? 'active' : ''
            }`}
            onClick={() => handleItemClick('surreymain')}
            style={{ marginTop: '15px' }}
          >

            <Link className="sidebarLink" to="/surrey/home">
              Surrey
            </Link>
          </h3>
          <ul className="sidebarList">
            <li
              className={`sidebarListItem ${selectedItem === 'surreygoals' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('surreygoals')}
            >
              <Link className="sidebarLink" to="/surrey/goals">
                Emission Goals and Status
              </Link>
            </li>
            <li
              className={`sidebarListItem ${selectedItem === 'surreyadd' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('surreyadd')}
            >
              <Link className="sidebarLink" to="/surrey/add">
                Add Emission Data
              </Link>
            </li>
            <li
              className={`sidebarListItem ${selectedItem === 'surreylive' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('surreylive')}
            >
              <Link className="sidebarLink" to="/surrey/live">
                Live Emission Data
              </Link>
            </li>
            <li
              className={`sidebarListItem ${selectedItem === 'surreypast' ? 'active' : ''
                }`}
              onClick={() => handleItemClick('surreypast')}
            >
              <Link className="sidebarLink" to="/surrey/past">
                Historical Emission Data
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
