import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import "./liveview.css";

const count = 12;
const arbitraryTrafficValue = 103032.2;
const minTrafficValue = 80000
const maxTrafficValue = 140000
const arbitraryBuildingValue = 129221.9
const minBuildingValue = 70000
const maxBuildingValue = 150000
const arbitraryIndustryValue = 24221.3
const minIndustryValue = 10000
const maxIndustryValue = 60000

// Initialize traffic trace

let xTrafficAxis = [];
let yTrafficAxis = [];
let labelTraffic = [];

for (let i = 0; i < count - 1; i++) {
  xTrafficAxis.push("")
  yTrafficAxis.push(0)
  labelTraffic.push("")
}
let xNewTrafficPoint = new Date();
const xNewTrafficPointFormatted = xNewTrafficPoint.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
xTrafficAxis.push(xNewTrafficPointFormatted);
let yNewTraffic = arbitraryTrafficValue
yTrafficAxis.push(yNewTraffic)
labelTraffic.push(yNewTraffic.toString())

// Initialize building trace

let xBuildingAxis = [];
let yBuildingAxis = [];
let labelBuilding = [];

for (let i = 0; i < count - 1; i++) {
  xBuildingAxis.push("")
  yBuildingAxis.push(0)
  labelBuilding.push("")
}
let xNewBuildingPoint = new Date();
const xNewBuildingPointFormatted = xNewBuildingPoint.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
xBuildingAxis.push(xNewBuildingPointFormatted);
let yNewBuilding = arbitraryBuildingValue
yBuildingAxis.push(yNewBuilding)
labelBuilding.push(yNewBuilding.toString())

// Initialize industry trace

let xIndustryAxis = [];
let yIndustryAxis = [];
let labelIndustry = [];

for (let i = 0; i < count - 1; i++) {
  xIndustryAxis.push("")
  yIndustryAxis.push(0)
  labelIndustry.push("")
}
let xNewIndustryPoint = new Date();
const xNewIndustryPointFormatted = xNewIndustryPoint.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
xIndustryAxis.push(xNewIndustryPointFormatted);
let yNewIndustry = arbitraryIndustryValue
yIndustryAxis.push(yNewIndustry)
labelIndustry.push(yNewIndustry.toString())

const startTime = new Date();

//---- Initialization

export default function Home() {
  const [rtData, setData] = useState(
    {
      xTrafficAxis: xTrafficAxis,
      yTrafficAxis: yTrafficAxis,
      labelTraffic: labelTraffic,
      typeTraffic: 'bar',
      nameTraffic: 'Traffic',

      xBuildingAxis: xBuildingAxis,
      yBuildingAxis: yBuildingAxis,
      labelBuilding: labelBuilding,
      typeBuilding: 'bar',
      nameBuilding: 'Buildings',

      xIndustryAxis: xIndustryAxis,
      yIndustryAxis: yIndustryAxis,
      labelIndustry: labelIndustry,
      typeIndustry: 'bar',
      nameIndustry: 'Industry',

      rightnow: startTime,
    }
  )

  //---- State changes

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prev) => {
        // Set Traffic ---
        // Set Traffic x
        let xTrafficAxis = prev.xTrafficAxis;
        xTrafficAxis = xTrafficAxis.slice(1);// remove first item
        let xNewTrafficPoint = new Date();
        const xNewTrafficPointFormatted = xNewTrafficPoint.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        xTrafficAxis.push(xNewTrafficPointFormatted)// add to end

        // Set Traffic y
        let yTrafficAxis = prev.yTrafficAxis
        yTrafficAxis = yTrafficAxis.slice(1);// remove first item
        let yNewTrafficPoint = yTrafficAxis.slice(-1)[0] + Math.floor(Math.random() * 7000) - 3500
        if (yNewTrafficPoint < minTrafficValue) yNewTrafficPoint = minTrafficValue
        if (yNewTrafficPoint > maxTrafficValue) yNewTrafficPoint = maxTrafficValue
        yTrafficAxis.push(yNewTrafficPoint)// Add to end

        // Set Traffic label
        let labelTraffic = prev.labelTraffic
        labelTraffic = labelTraffic.slice(1);// remove first item
        labelTraffic.push(yNewTrafficPoint.toString())// Add to end

        // Set Building ---
        // Set Building x
        let xBuildingAxis = prev.xBuildingAxis;
        xBuildingAxis = xBuildingAxis.slice(1);// remove first item
        let xNewBuildingPoint = new Date();
        const xNewBuildingPointFormatted = xNewBuildingPoint.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        xBuildingAxis.push(xNewBuildingPointFormatted)// add to end

        // Set Building y
        let yBuildingAxis = prev.yBuildingAxis
        yBuildingAxis = yBuildingAxis.slice(1);// remove first item
        let yNewBuildingPoint = yBuildingAxis.slice(-1)[0] + Math.floor(Math.random() * 12000) - 6000
        if (yNewBuildingPoint < minBuildingValue) yNewBuildingPoint = minBuildingValue
        if (yNewBuildingPoint > maxBuildingValue) yNewBuildingPoint = maxBuildingValue
        yBuildingAxis.push(yNewBuildingPoint)// Add to end

        // Set Building label
        let labelBuilding = prev.labelBuilding
        labelBuilding = labelBuilding.slice(1);// remove first item
        labelBuilding.push(yNewBuildingPoint.toString())// Add to end

        // Set Industry ---
        // Set Industry x
        let xIndustryAxis = prev.xIndustryAxis;
        xIndustryAxis = xIndustryAxis.slice(1);// remove first item
        let xNewIndustryPoint = new Date();
        const xNewIndustryPointFormatted = xNewIndustryPoint.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        xIndustryAxis.push(xNewIndustryPointFormatted)// add to end

        // Set Industry y
        let yIndustryAxis = prev.yIndustryAxis
        yIndustryAxis = yIndustryAxis.slice(1);// remove first item
        let yNewIndustryPoint = yIndustryAxis.slice(-1)[0] + Math.floor(Math.random() * 12000) - 6000
        if (yNewIndustryPoint < minIndustryValue) yNewIndustryPoint = minIndustryValue
        if (yNewIndustryPoint > maxIndustryValue) yNewIndustryPoint = maxIndustryValue
        yIndustryAxis.push(yNewIndustryPoint)// Add to end

        // Set Industry label
        let labelIndustry = prev.labelIndustry
        labelIndustry = labelIndustry.slice(1);// remove first item
        labelIndustry.push(yNewIndustryPoint.toString())// Add to end

        return {
          xTrafficAxis: xTrafficAxis,
          yTrafficAxis: yTrafficAxis,
          labelTraffic: labelTraffic,
          typeTraffic: 'bar',
          nameTraffic: 'Traffic',

          xBuildingAxis: xBuildingAxis,
          yBuildingAxis: yBuildingAxis,
          labelBuilding: labelBuilding,
          typeBuilding: 'bar',
          nameBuilding: 'Building',

          xIndustryAxis: xIndustryAxis,
          yIndustryAxis: yIndustryAxis,
          labelIndustry: labelIndustry,
          typeIndustry: 'bar',
          nameIndustry: 'Industry',

          rightnow: new Date()
        };
      });
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // callAPI function that takes the base and exponent numbers as parameters
  var getTrafficEmissions = () => {
    // instantiate a headers object
    var myHeaders = new Headers();
    // add content type header to object
    myHeaders.append("Content-Type", "application/json");
    // create a JSON object with parameters for API call and store in a variable
    var requestOptions = {
      mode: 'cors',
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    // make API call with parameters and use promises to get response
    // fetch("https://04c255ttmk.execute-api.us-east-1.amazonaws.com/prod", requestOptions)
    // .then(response => response.json())
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
  }

  getTrafficEmissions();

  return (
    <div className="mainPage">
      {/* <SummaryInfo /> */}
      <div className="mainSummary">
        <div className="mainSummaryContainer">
          <div className="mainSummaryTitle">City of Surrey - View Live Data</div>
          <br></br>
          <h3>Real-time Emissions (kgCO<span className="super">2</span>e)</h3>
          <div className="container">
            <div className="chart1" id="plot1">
              <Plot
                data={
                  [
                    {
                      x: rtData.xBuildingAxis,
                      y: rtData.yBuildingAxis,
                      text: rtData.labelBuilding,
                      name: rtData.nameBuilding,
                      textposition: 'auto',
                      type: rtData.typeBuilding,
                      width: 0.5
                    }, {
                      x: rtData.xTrafficAxis,
                      y: rtData.yTrafficAxis,
                      text: rtData.labelTraffic,
                      name: rtData.nameTraffic,
                      textposition: 'auto',
                      type: rtData.typeTraffic,
                      width: 0.5
                    }, {
                      x: rtData.xIndustryAxis,
                      y: rtData.yIndustryAxis,
                      text: rtData.labelIndustry,
                      name: rtData.nameIndustry,
                      textposition: 'auto',
                      type: rtData.typeIndustry,
                      width: 0.5
                    }
                  ]}
                layout={{
                  plot_bgcolor: "#f5faf6",
                  paper_bgcolor: "#f5faf6",
                  barmode: 'stack',
                  width: 900, height: 550,
                  showlegend: true,
                  legend: {
                    x: .5, y: 1.1,
                    xanchor: 'center', yanchor: 'top',
                    orientation: "h",
                    font: {
                      size: 16
                    }
                  },
                  title: "<b>Hourly Emissions</b> (Last Updated: " + rtData.rightnow.toDateString() + " " + rtData.rightnow.toLocaleTimeString() + ")",
                }}
              />
            </div>
            <div className="chart2">
              <div className="plot2">
                <Plot className="spanStyle"
                  data={
                    [
                      {
                        type: "indicator",
                        mode: "number+delta",
                        value: 12992,
                        title: {
                          text: "<span style='font-size:20;color:black;font-weight: bold;'>May [Projected]</span><br><br><span style='font-size:17;color:gray'>(Metric Tonnes)</span>"
                        },
                        number: {
                          font: { size: 40 }
                        },
                        delta: { reference: 13000, relative: true },
                        domain: { x: [0, 1], y: [1, 1] }
                      },
                    ]}
                  layout={{
                    plot_bgcolor: "#f5faf6",
                    paper_bgcolor: "#f5faf6",
                    width: 200, height: 200,
                    margin: { t: 0, r: 0, l: 0, b: 0 }
                  }}
                />
              </div>
              <div className="plot2">
                <Plot className="spanStyle"
                  data={
                    [
                      {
                        type: "indicator",
                        mode: "number+delta",
                        value: 4380000,
                        title: {
                          text: "<span style='font-size:20;color:black;font-weight: bold;'>2023 [Projected]</span><br><br><span style='font-size:17;color:gray'>(Metric Tonnes)</span>"
                        },
                        number: {
                          font: { size: 40 }
                        },
                        delta: { reference: 4300000, relative: true },
                        domain: { x: [0, 1], y: [1, 1] }
                      },
                    ]}
                  layout={{
                    plot_bgcolor: "#f5faf6",
                    paper_bgcolor: "#f5faf6",
                    width: 200, height: 200,
                    margin: { t: 0, r: 0, l: 0, b: 0 }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
