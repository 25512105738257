import React from 'react';
import './topbar.css';
import greencityLogo from './greencity.png'
import greencitydataLogo from './green-city-data-logo.jpg'
import { NotificationsNone, Settings } from '@material-ui/icons';

export default function Topbar() {
  return (
  <div className='topbar'>
      <div className='topbarWrapper'>
        <div className="topLeft">
            <span className='logo'>
                <img className='logoImg' src={greencitydataLogo} alt='cov-logo' />
            </span>
        </div>
      </div>
  </div>
  )
}
