import React, { useState } from 'react';
import './emissions.css'; // Import the CSS file

const Enter = () => {
  const [emissionType, setEmissionType] = useState('transportation');
  const [emissionSubType, setEmissionSubType] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [emissionValue, setEmissionValue] = useState('');

  const handleEmissionTypeChange = (event) => {
    setEmissionType(event.target.value);
  };

  const handleEmissionSubTypeChange = (event) => {
    setEmissionSubType(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleEmissionValueChange = (event) => {
    setEmissionValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Convert emissionType to numerical value
    let emissionTypeValue;
    switch (emissionType) {
      case 'transportation':
        emissionTypeValue = 0;
        break;
      case 'buildings':
        emissionTypeValue = 1;
        break;
      case 'industry':
        emissionTypeValue = 2;
        break;
      case 'other':
        emissionTypeValue = 3;
        break;
      default:
        emissionTypeValue = 0; // Set a default value if none matches
    }

    const queryParams = new URLSearchParams();
    queryParams.append('location', 0);
    queryParams.append('start', selectedDate);
    queryParams.append('type', emissionTypeValue);
    queryParams.append('subtype', 0);
    queryParams.append('emissions', parseFloat(emissionValue));

    const queryString = queryParams.toString();
    const url = `https://dp9n0e20tc.execute-api.us-east-1.amazonaws.com/prod/emissions?${queryString}`;

    fetch(url, {
      method: 'POST',
      mode: 'no-cors', // Disable CORS
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*' // Set desired Access-Control-Allow-Origin value
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="mainPage">
      <div className="mainSummary">
        <div className="mainSummaryContainer">
          <div className="mainSummaryTitle">City of Surrey - Add Emission Data</div>
          <br></br>
          <div className="container">

            <form className="enterForm" onSubmit={handleSubmit}>
              <div>
                <label>Emission Type:</label>
                <select value={emissionType} onChange={handleEmissionTypeChange}>
                  <option value="transportation">Transportation</option>
                  <option value="buildings">Buildings</option>
                  <option value="industry">Industry</option>
                  <option value="other">Other</option>
                </select>
              </div>
              {/* <div>
                <label>Emission Sub-Type:</label>
                <input
                  type="text"
                  value={emissionSubType}
                  onChange={handleEmissionSubTypeChange}
                />
              </div> */}
              <div>
                <label>Start Date:</label>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
              <div>
                <label>Emission Value:</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="10000"
                  value={emissionValue}
                  onChange={handleEmissionValueChange}
                />
              </div>
              <button type="submit">Submit</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Enter;

