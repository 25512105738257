import React, { useState, useEffect } from "react";
import "./goals.css";
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import AnnualEmissionsChart from "./Annual";

export default function Goals() {

  return (
    <div className="mainPage">
      {/* <SummaryInfo /> */}
      <div className="mainSummary">
        <div className="mainSummaryContainer">
          <div className="mainSummaryTitle">City of Surrey - Goals and Status</div>
          <div className="container">
            <AnnualEmissionsChart />
          </div>
        </div>
      </div>
    </div>
  );
}
