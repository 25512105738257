import React, { useState, useEffect } from "react";
import "./pastview.css";
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';

const today = new Date();

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  return Array.from({ length: count }, (_, i) => i);
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function PastView() {

  const emissions = getRange(365).map(index => {
    return {
      date: shiftDate(today, -index),
      count: getRandomInt(1, 10),
    };
  });

  return (
    <div className="mainPage">
      {/* <SummaryInfo /> */}
      <div className="mainSummary">
        <div className="mainSummaryContainer">
          <div className="mainSummaryTitle">City of Surrey - Historical Data</div>
          <div className="container">
            <div className="calendar-heatmap">
              <CalendarHeatmap
        startDate={shiftDate(today, -365)}
        endDate={today}
                values={emissions}
                classForValue={value => {
                  if (!value) {
                    return 'color-empty';
                  }
                  return `color-${value.count}`;
                }}
                tooltipDataAttrs={value => {
                  return {
                    'data-tip': `${value.date.toISOString().slice(0, 10)} has count: ${value.count}`,
                  };
                }}
                showWeekdayLabels={true}
                onClick={value => alert(`Clicked on value with count: ${value.count}`)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
