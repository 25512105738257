import React from 'react';
import Plot from 'react-plotly.js';
import { useTable } from 'react-table';
import './annual.css';

const EmissionChart = () => {
  // Sample data for demonstration purposes
  const previousYearData = [50, 55, 53, 56, 59]; // Previous year emissions
  const timeSeriesData = [45, 48, 50, 52, 55, 57]; // Daily emissions
  const tableData = [
    { category: 'Current', value: 55 },
    { category: 'Forecasted', value: 58 },
    { category: 'Targets', value: 50 },
  ]; // Emission data for the year

  // Bar chart configuration
  const previousYearConfig = {
    x: ['2018', '2019', '2020', '2021', '2022'],
    y: previousYearData,
    type: 'bar',
    marker: {
      color: 'rgba(75,192,192,0.6)',
      line: {
        color: 'rgba(75,192,192,1)',
        width: 1,
      },
    },
  };

  // Time series chart configuration
  const timeSeriesConfig = {
    x: [
      '2023-01-01',
      '2023-02-01',
      '2023-03-01',
      '2023-04-01',
      '2023-05-01',
      '2023-06-01',
      '2023-07-01',
      // Add more monthly x-axis values as needed
    ],
    y: timeSeriesData,
    mode: 'lines',
    line: {
      color: 'rgba(75,192,192,1)',
    },
  };

  // Table columns
  const columns = [
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Value',
      accessor: 'value',
    },
  ];

  // Table data
  const tableRows = tableData.map((row) => ({ ...row, id: row.category }));

  // React Table hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableRows,
  });

  return (
    <div className="emission-chart">
      <div className="column">
        <h3 className='column-headings'>Previous Year Emissions</h3>
        <Plot
          data={[previousYearConfig]}
          layout={{ title: 'Previous Year Emissions' }}
        />
      </div>
      <div className="column">
        <h3 className='column-headings'>Daily Emissions</h3>
        <Plot
          data={[timeSeriesConfig]}
          layout={{
            title: 'Daily Emissions',
            xaxis: {
              tickmode: 'linear',
              tick0: '2023-01-01',
              dtick: 'M1',
            },
          }}
        />
      </div>
      <div className="column">
        <h3 className='column-headings'>Targets/Goals</h3>
        <table {...getTableProps()} className="emission-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmissionChart;
