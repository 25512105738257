import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import GreenCityData from "./pages/home/Home";
import NewWestMain from "./pages/newwest/main/Main";
import NewWestGoals from "./pages/newwest/goals/Goals";
import NewWestEmissions from "./pages/newwest/emissions/Emissions";
import NewWestLiveView from "./pages/newwest/liveview/LiveView";
import NewWestPastView from "./pages/newwest/pastview/PastView";
import SurreyMain from "./pages/surrey/main/Main";
import SurreyGoals from "./pages/surrey/goals/Goals";
import SurreyEmissions from "./pages/surrey/emissions/Emissions";
import SurreyLiveView from "./pages/surrey/liveview/LiveView";
import SurreyPastView from "./pages/surrey/pastview/PastView";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

const App = () => {
  return (
    <div>
      <Topbar />
      <div className="container">
      <BrowserRouter>
      <Sidebar />
        <Routes>
          <Route exact path="/" element={<GreenCityData />} />
          <Route exact path="/newwest/home" element={<NewWestMain />} />
          <Route exact path="/newwest/goals" element={<NewWestGoals />} />
          <Route exact path="/newwest/live" element={<NewWestLiveView />} />
          <Route exact path="/newwest/past" element={<NewWestPastView />} />
          <Route exact path="/newwest/add" element={<NewWestEmissions />} />
          <Route exact path="/surrey/home" element={<SurreyMain />} />
          <Route exact path="/surrey/goals" element={<SurreyGoals />} />
          <Route exact path="/surrey/live" element={<SurreyLiveView />} />
          <Route exact path="/surrey/past" element={<SurreyPastView />} />
          <Route exact path="/surrey/add" element={<SurreyEmissions />} />
        </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
