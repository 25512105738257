import React, { useState, useEffect } from "react";
import "./home.css";
import homeChart from './chart.jpg';

export default function Home() {
  return (
    <div className="homePage">
      <div className="contentContainer">
      <div className="imageColumn">
          <img src={homeChart} alt="Green City Data" className="image" />
        </div>
        <div className="textColumn">
          <h2 className="homeSummaryTitle">Green City Data: Real-Time Data. Real Impact.</h2>
          <p className="homeSummaryText">We are Green City Data, your partner in driving sustainable change. With expertise in IoT, data analytics, and machine learning, we provide real-time and granular environmental solutions for governments and organizations.</p>
          <br></br>
          <h3 className="sectionTitle">What we do:</h3>
          <ul className="bulletList">
            <li>
              <strong>Empower informed decisions:</strong> Our cutting-edge technology delivers real-time data and insights, enabling you to make informed policy decisions that lower your carbon footprint.
            </li>
            <li>
              <strong>Bridge the gap:</strong> We help close the divide between climate goals and reality by offering live calculators that track progress towards emission targets, saving costs and reducing greenhouse gas emissions.
            </li>
          </ul>
          <br></br>
          <h3 className="sectionTitle">What we’re working on:</h3>
          <ul className="bulletList">
            <li>
              <strong>Innovating for a sustainable future:</strong> We leverage the latest advancements in the climate tech sphere to deliver innovative solutions that provide real value to customers, citizens, and the planet as a whole.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
